import React from "react"
//import {Link} from "react-router-dom";
import { t } from "i18next"
// import './custom.css'
// import { useTranslation } from 'react-i18next'
import { send } from 'emailjs-com'
import ReCAPTCHA from "react-google-recaptcha"
import {NotificationManager} from 'react-notifications'
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Link } from "react-router-dom"



const PrihlaskaSeminar = () => {
  const captchaRef = React.useRef()

  const defaultValues = {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      message: '',
      // event: '101 Gracie Combatives seminár - 21. septembra 2024',
      event: '101 Gracie WE seminár - 8. marec 2025 o 10:00',
  }

  const onSubmit = (values) => {
      const token = captchaRef.current.getValue()
      send(
          'service_roxp2n8',
          'template_d6rrhyt',
          {
              ...values,
              'g-recaptcha-response': token,
          },
          'i0xGfmqONO5OrVi-a'
      )
      .then((response) => {
          console.log('SUCCESS!', response.status, response.text)
          NotificationManager.success('Success message', 'Email bol odoslaný.', 3000);
      })
      .catch((err) => {
          console.log('FAILED...', err)
          NotificationManager.error('Warning message', 'Email nebol odoslaný. Vyskytla sa chyba.', 3000);
      })


  }

  const validationSchema = Yup.object({
      firstname: Yup.string().required().min(1, "Krstné meno dieťaťa je hodnota, ktorú je potrebné vyplnit"),
      lastname: Yup.string().required().min(1, "Priezvisko dieťaťa je hodnota, ktorú je potrebné vyplnit"),
      email: Yup.string().email().required().min(1, "Email je hodnota, ktorú je potrebné vyplnit"),
      phone: Yup.string().optional().nullable(),
      message: Yup.string().optional().nullable(),
      event: Yup.string().optional().nullable()
  })

  
return (

<div>

  
  {/* Contact form element & details section with custom paddings */}
  <section className="hg_section pb-80" style={{marginTop: "-50px"}}>
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-9 col-lg-9 mb-sm-30">
          {/* Contact form element */}
          <div className="contactForm">
            <Formik initialValues={defaultValues} validationSchema={validationSchema} onSubmit={async (values, { resetForm }) => { await onSubmit(values); resetForm(); }} >
            <Form className="contact_form row">
            <Field name="event" type="hidden"  />


            {/* <form action="php_helpers/_contact-process.php" method="post" className="contact_form row" encType="multipart/form-data"> */}
              {/* Response wrapper */}
              <div className="cf_response" />
              <div className="col-sm-6 kl-fancy-form">
                {/* <input type="text" name="name" id="cf_name" className="form-control" placeholder="Please enter your first name" defaultValue tabIndex={1} maxLength={35} required /> */}
                <Field
                    name="firstname"
                    id="cf_firstname" 
                    className="form-control" 
                    placeholder={t('yourFirstname')}
                    tabIndex={1} 
                    maxLength={35} 
                    required
                    type="text"                    
                />
                <div className="text-danger"><strong><ErrorMessage name="firstname" /></strong></div>                            

                <label className="control-label">
                  {t('firstname').toUpperCase()}
                </label>
              </div>

              <div className="col-sm-6 kl-fancy-form">
                {/* <input type="text" name="lastname" id="cf_lastname" className="form-control" placeholder="Please enter your first last name" defaultValue tabIndex={1} maxLength={35} required /> */}
                <Field
                    name="lastname"
                    id="cf_lastename" 
                    className="form-control" 
                    placeholder={t('yourLastname')}            
                    tabIndex={1} 
                    maxLength={35} 
                    required
                    type="text"                    
                />
                <div className="text-danger"><strong><ErrorMessage name="lastname" /></strong></div>                            
                <label className="control-label">
                {t('lastname').toUpperCase()} 
                </label>
              </div>

              <div className="col-sm-12 kl-fancy-form">
                {/* <input type="text" name="email" id="cf_email" className="form-control h5-email" placeholder="Please enter your email address" defaultValue tabIndex={1} maxLength={35} required /> */}
                <Field
                    name="email"
                    id="cf_email" 
                    className="form-control h5-email" 
                    placeholder={t('yourEmail')}
                    tabIndex={1} 
                    maxLength={50} 
                    type="text"                    
                />
                <div className="text-danger"><strong><ErrorMessage name="email" /></strong></div>                            

                <label className="control-label">
                {t('email').toUpperCase()}
                </label>
              </div>

              <div className="col-sm-12 kl-fancy-form">
                <Field
                    name="phone"
                    id="cf_phone" 
                    className="form-control" 
                    placeholder="Telefonny kontakt na rodiča"
                    tabIndex={1} 
                    maxLength={50} 
                    type="text"                    
                />
                <div className="text-danger"><strong><ErrorMessage name="subject" /></strong></div>                            

                <label className="control-label">
                Telefónny kontakt
                </label>
              </div>


              <div className="col-sm-12 kl-fancy-form">
                <Field component="textarea" name="message" id="cf_message" className="form-control" cols={30} rows={10} placeholder="Priestor na vaše prípadné otázky." tabIndex={4} />
                <div className="text-danger"><strong><ErrorMessage name="message" /></strong></div>                            
                <label className="control-label">
                {t('message').toUpperCase()}

                </label>
              </div>

              <div className="col-sm-12 mb-30 pb-10 pt-10 border">
                
                <label className="control-label">
                  <span><strong>ZASLANÍM PRIHLÁŠKY UDEĽUJEM SÚHLAS, V SÚLADE S PLATNÝM ZÁKONOM O OCHRANE OSOBNÝCH ÚDAJOV A BERIEM NA VEDOMIE PRAVIDLÁ A PODMIENKY ÚČASTI</strong></span>
                  <ol className="list-group list-group-numbered">
                    <li><Link to="/gdpr">Zásady spracúvania osobných údajov (GDPR)</Link></li>
                  </ol>

                </label>
              </div>


              <div className="col-sm-6">
                <ReCAPTCHA
                    sitekey="6LdiYGAkAAAAAB896FlVk3v6yxe9A5-3YTgrFtXF"
                    ref={captchaRef}
                />                            
              </div>
              <div className="col-sm-6 text-right">
                {/* Contact form send button */}
                <button className="btn btn-fullcolor" type="submit">
                Prihlásiť sa na seminár
                </button>
              </div>
            {/* </form> */}
            </Form>
            </Formik>

          </div>
          {/*/ Contact form element */}
        </div>
        {/*/ col-sm-12 col-md-9 col-lg-9 mb-sm-30 */}
        <div className="col-sm-12 col-md-3 col-lg-3">
          {/* Contact details */}
          <div className="text_box">
            {/* Title */}
            <h3 className="kl-iconbox__title">
              Priháška
            </h3>
            <h4 style={{fontSize: "90%"}}>Seminár sebaobrany pre ženy</h4>
            <br />
            {/* Sub-title */}
            <p>
              <i className="fa fa-at"></i> <a href="mailto:mailto://info@bjjlevice.sk">info@bjjlevice.sk</a><br />
              <i className="fa fa-phone"></i> <span>0918 107 782</span>
            </p>
          </div>
          {/*/ Contact details */}


          <div className="text-center pt-20 card" style={{borderColor: "#cd2122"}}>
            <div style={{margin: "10px"}}>
              <div className="pb-30">
                {/* <img src="/images/15.png" alt="15% zlava" /> */}
              </div>
              <div className="fs-m reset-line-height">
                Prihláste sa už dnes! 
                {/* <strong className="kl-iconbox__title">ZĽAVA 15%</strong> pre prvých 15 registrácii. */}
              </div>
              
              <div className="nlbox--hugediscount text-center-sm" />
              
              <div className="fs-m reset-line-height mb-30">
                8. marec 2025 <br /><br />
                od 10:00 do 11:30
              </div>
              
              <div className="nlbox--hugediscount text-center-sm" />

              <div className="fs-m reset-line-height mb-30 pt-30 kl-iconbox__title">
              <div style={{weight: "999"}} className="pb-10">AKTUÁLNA CENA JE</div>
              <div style={{fontSize: "200%", weight: "999"}}><strong>0 &euro;</strong></div>
              </div>

            </div>
          </div>




        </div>
        {/*/ col-sm-12 col-md-3 col-lg-3 */}
      </div>
      {/*/ .row */}
    </div>
    {/*/ .container */}
  </section>
  {/*/ Contact form element & details section with custom paddings */}
</div>

    )
}
export default PrihlaskaSeminar