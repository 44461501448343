import React from "react"
import PageHeader from "../../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../../components/Footer"
// import RozvrhTreningov from "../components/RozvrhTreningov"
import { useTranslation } from 'react-i18next'
import AdultsComponent from "../../components/AdultsComponent"
import HarmonogramComponent from "../../components/HarmonogramComponent"
import NaborFormular from "../../components/NaborFormular"

const GracieCombatives = () => {
    const {t} = useTranslation('translation')
    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    
    // useEffect(() => {
    //     if (userProfile.roles) { setHasRoles(true) }
    //     if (userProfile.awards) { setHasAwards(true) }
    //     if (userProfile.adultRanks) { setHasAdultRanks(true) }
    // }, [userProfile])
    

    return (
        <div>
            <PageHeader />


            <div id="page_header" className="page-subheader site-subheader-cst">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url(images/general-bg-3.jpg)', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPositionX: 'center', backgroundPositionY: 'center', backgroundSize: 'cover'}} />
                    <div className="kl-bg-source__overlay" style={{backgroundColor: 'rgba(53,53,53,0.1)'}} />
                </div>

                <div className="ph-content-wrap d-flex">
                <div className="container align-self-center">
                    <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="clearfix" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6" style={{backgroundColor: 'rgba(0,0,0,0.5)', padding: 15,}}>
                        <div className="subheader-titles">
                        <h2 className="subheader-maintitle">Gracie Combatives<sup>®</sup></h2>
                        <h4 className="subheader-subtitle">{t('page.beginers')}</h4>
                        </div>
                    </div>
                    </div>
                </div>
                </div>

                {/* <div className="kl-bottommask kl-bottommask--mask3">
                
                <svg width="2700px" height="57px" className="svgmask" viewBox="0 0 2700 57" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-mask3">
                    <feOffset dx={0} dy={3} in="SourceAlpha" result="shadowOffsetInner1" />
                    <feGaussianBlur stdDeviation={2} in="shadowOffsetInner1" result="shadowBlurInner1" />
                    <feComposite in="shadowBlurInner1" in2="SourceAlpha" operator="arithmetic" k2={-1} k3={1} result="shadowInnerInner1" />
                    <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.4 0" in="shadowInnerInner1" type="matrix" result="shadowMatrixInner1" />
                    <feMerge>
                        <feMergeNode in="SourceGraphic" />
                        <feMergeNode in="shadowMatrixInner1" />
                    </feMerge>
                    </filter>
                </defs>
                <path d="M-2,57 L-2,34.007 L1268,34.007 L1284,34.007 C1284,34.007 1291.89,33.258 1298,31.024 C1304.11,28.79 1329,11 1329,11 L1342,2 C1342,2 1345.121,-0.038 1350,-1.64313008e-14 C1355.267,-0.03 1358,2 1358,2 L1371,11 C1371,11 1395.89,28.79 1402,31.024 C1408.11,33.258 1416,34.007 1416,34.007 L1432,34.007 L2702,34.007 L2702,57 L1350,57 L-2,57 Z" className="bmask-bgfill" filter="url(#filter-mask3)" fill="#fbfbfb" />
                </svg>

              <i className="fas fa-angle-down" />
            </div> */}
            
            </div>


 
            







            <section className="hg_section hg_section--relative">
                {/*** <div className="kl-bg-source kl-bgSource-imageParallax js-KyHtmlParallax is-fixed is-visible" style={{height: 625, width: 1231, transform: 'translate3d(0px, 129.898px, 0px)'}}>
                    <div className="kl-bg-source__bgimage" style={{backgroundImage: 'url("images/photo-1431578500526-4d9613015464-resized.jpg")', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPosition: '50% 50%', backgroundSize: 'cover', transform: 'translate3d(0px, -100px, 0px)'}} />
                </div>
                 ***/}
                <div className="container">
                    <div className="row">

                        <div className="col-sm-12 col-md-12">
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="left-side">
                                        <img src="images/Gracie-Combatives-Lockup-Logo-COLOR-1200x273.png" alt="The Gracie Combatives® Program" />
                                    <br />

                                        {/* <h3 className="kl-font-alt fs-larger tcolor fw-bold title">                                             */}
                                            {/* <span className="fw-thin"> */}
                                            {/* <span className="fw-semibold tcolor"></span> */}
                                            {/* </span> */}
                                        {/* </h3> */}
                                        <p>{t('program.gc1')}</p>
                                        <p>{t('program.gc2')}</p>
                                        {/*** 
                                        <ul className="features">
                                            <li><p>BJJ je dizajnovane pre sebeobranu aj proti vacsiemu a silnejsiemu oponentovi.</p></li>
                                            <li><p>Nieje potreba ziadnich predchazajucich skusenosti s bojovim umenim</p></li>
                                        </ul>
                                        <a href="#" target="_blank" className="btn btn-fullcolor btn-third">BUY NOW</a> 
                                        ***/}
                                    </div>
                                </div>
                                
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                        <iframe title="gc1" src="https://www.youtube.com/embed/xZenj6WFkHA?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section id="services" className="hg_section pt-60 pb-100">
            <div className="container">
                <div className="row mb-50">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-titl pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.gc3')}
                    </h3>
                    {/* 
                    <div className="tbk__symbol">
                        <span className="tbk__icon fs-xxl light-tcolor fas fa-ellipsis-h" />
                    </div> 
                    */}
                    {/* 
                    <h4 className="tbk__subtitle fs-m light-tcolor">
                        WE'RE GOOD AT SOME STUFF AND VERY GOOD AT OTHER STUFF
                    </h4> 
                    */}
                    <p>{t('program.gc4')}</p>
                    </div>
                </div>
                </div>
                <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="services_box services_box--boxed sb--hasicon">	
                    <div className="services_box__inner clearfix">
                        {/* <div className="services_box__icon">
                        <div className="services_box__icon-inner">
                            <span className="services_box__fonticon icon-noun_65754" />
                        </div>
                        </div> */}
                        <div className="services_box__content">
                        <h4 className="services_box__title">
                        {t('step')} 1: <br />{t('program.gc5')}
                        </h4>
                        <div className="services_box__desc">
                            <p>
                            {t('program.gc6')}
                            </p>
                        </div>
                        {/* <div className="services_box__list-wrapper">
                            <span className="services_box__list-bg" />
                            <ul className="services_box__list" style={{paddingTop: 250}}>
                            <li><span className="services_box__list-text">Content management systens </span></li>
                            <li><span className="services_box__list-text">Virtual shops and ecommerce </span></li>
                            <li><span className="services_box__list-text">Presentation websites </span></li>
                            <li><span className="services_box__list-text">Online catalogues </span></li>
                            <li><span className="services_box__list-text">Portal systems </span></li>
                            <li><span className="services_box__list-text">&amp; any other dynamic website </span></li>
                            </ul>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="services_box services_box--boxed sb--hasicon">	
                    <div className="services_box__inner clearfix">
                        {/* <div className="services_box__icon">
                        <div className="services_box__icon-inner">
                            <span className="services_box__fonticon icon-process2" />
                        </div>
                        </div> */}
                        <div className="services_box__content">
                        <h4 className="services_box__title">
                        {t('step')} 2: <br />{t('program.gc7')}
                        </h4>
                        <div className="services_box__desc">
                            <p>
                            {t('program.gc8')}
                            </p>
                        </div>
                        {/* <div className="services_box__list-wrapper">
                            <span className="services_box__list-bg" />
                            <ul className="services_box__list" style={{paddingTop: 250}}>
                            <li><span className="services_box__list-text">Logo design </span></li>
                            <li><span className="services_box__list-text">Flyers &amp; Brochures</span></li>
                            <li><span className="services_box__list-text">Business Cards</span></li>
                            <li><span className="services_box__list-text">Stationery Design</span></li>
                            <li><span className="services_box__list-text">Powerpoint presentation</span></li>
                            <li><span className="services_box__list-text">Print Ads / Magazine</span></li>
                            <li><span className="services_box__list-text">Banners</span></li>
                            </ul>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="services_box services_box--boxed sb--hasicon">	
                    <div className="services_box__inner clearfix">
                        {/* <div className="services_box__icon">
                        <div className="services_box__icon-inner">
                            <span className="services_box__fonticon icon-process3" />
                        </div>
                        </div> */}
                        <div className="services_box__content">
                        <h4 className="services_box__title">
                        {t('step')} 3: <br />{t('program.gc9')}
                        </h4>
                        <div className="services_box__desc">
                            <p>
                            {t('program.gc10')}
                            </p>
                        </div>
                        {/* <div className="services_box__list-wrapper">
                            <span className="services_box__list-bg" />
                            <ul className="services_box__list" style={{paddingTop: 250}}>
                            <li><span className="services_box__list-text">Search engine optimisation</span></li>
                            <li><span className="services_box__list-text">Search engine marketing</span></li>
                            <li><span className="services_box__list-text">Internet advertising</span></li>
                            <li><span className="services_box__list-text">Web analysis</span></li>
                            <li><span className="services_box__list-text">Blog marketing</span></li>
                            </ul>
                        </div> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>


            <AdultsComponent />




            <HarmonogramComponent  category={["adults-beginers"]} />


            <section id="services" className="hg_section">
            <div className="container">
                <div className="row">
                <div className="col-sm-12 col-md-12">
                    <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-title pbottom-30">
                    <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">
                    {t('program.gc11')}
                    </h3>
                    {/* 
                    <div className="tbk__symbol">
                        <span className="tbk__icon fs-xxl light-tcolor fas fa-ellipsis-h" />
                    </div> 
                    */}
                    {/* 
                    <h4 className="tbk__subtitle fs-m light-tcolor">
                        WE'RE GOOD AT SOME STUFF AND VERY GOOD AT OTHER STUFF
                    </h4> 
                    */}
                    <p>{t('program.gc12')}</p>
                    </div>
                </div>
                </div>
            </div>
            </section>



            <section id="services" className="hg_section">
            <div className="container">
                <div className="row">

                    <div className="col-sm-12 col-md-12">
                        <div className="screenshot-box">
                            <div className="row">

                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="gc2" src="https://www.youtube.com/embed/LGO31XqkevM?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="gc3" src="https://www.youtube.com/embed/LgRGiOXu_fA?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                                <div className="col-sm-12 col-md-12 col-lg-4">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                    <iframe title="gc4" src="https://www.youtube.com/embed/rLE_StI8aHw?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
            </section>









            <NaborFormular eventName="Gracie Combatives" />
            
            <PageFooter />
        </div>
    )
}

export default GracieCombatives

