// import {Link} from "react-router-dom";


function WESeminarComponent(props) {

    return (

        <div>


            <div id="page_header" className="page-subheader site-subheader-cst ">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{ backgroundImage: 'url("images/general-bg-we.jpeg")', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPosition: '50% 50%', backgroundSize: 'cover' }} />
                    <div className="kl-bg-source__overlay" style={{ backgroundColor: 'rgba(53, 53, 53, 0.1)' }} />
                </div>
                <div className="ph-content-wrap d-flex"><div className="container align-self-center">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="clearfix" /></div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="subheader-titles" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 15 }}>
                                    <h2 className="subheader-maintitle">101 seminár</h2>
                                    <h4 className="subheader-subtitle">výlučne pre ludí s nulovou skúsenosťou</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <section className="hg_section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-titl">
                                <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">Bezplatný seminár sebaobrany pre žieny</h3>
                                <p>Sme radi, že sa k nám pripojíte na bezplatnom seminári sebaobrany žien!</p>
                                <p>Ak uvažujete o tom, že by ste vyskúšali brazílske jiu-jitsu (BJJ), ale nechcete sa zraniť alebo zahanbiť, tento seminár je presne to, čo musíte navštíviť!</p>
                                <p>Ak sa chcete dozvedieť viac o udalosti, pozrite si toto video s Eve a Rener Gracie.</p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>


            {/* https://www.youtube.com/watch?v=FiwCTCpSxHY */}
            <section className="hg_section" style={{backgroundColor: "#f0f0f0"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="screenshot-box">
                                <div className="row">
                                    <div className="fluid-width-video-wrapper" style={{paddingTop: '75%'}}>
                                        <iframe title="gc2" src="https://www.youtube.com/embed/k-RIjcNg02I?rel=0&controls=0&showinfo=0" allowFullScreen />
                                    </div>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>




            <section id="services" className="hg_section">
                <div className="container">

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="th-spacer clearfix" style={{ height: 25 }}>
                            </div>
                            <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-titl">
                                {/* <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">Free Gracie Jiu-Jitsu 101 Seminar</h3> */}
                                <p>Na tomto špeciálnom 2-hodinovom bezplatnom seminári získate základné informácie o histórii BJJ a následne sa naučíte niekoľko techník z Women Empowered, popredného svetového programu BJJ pre ženy.</p>

                                <p>Toto podujatie sa koná, v ústredí Gracie University a v BJJ HOME našom gyme na Holubyho 5</p>

                                <p>Hoci nie sú potrebné žiadne skúsenosti, záujem o tento seminár často presiahne kapacitu, preto odporúčame registráciu vopred.</p>

                                <p>Toto podujatie je najbezpečnejším a najpríjemnejším spôsobom, ako sa zoznámiť s brazílskym Jiu-Jitsu, takže sa naozaj tešíme, že sa s vami zoznámime.</p>
                            </div>



                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/icon_thumbs-up.png" style={{width: "100px"}} alt="" title="" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Čo ak nemám skúsenosti?
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Perfektné! Tento seminár je určený pre vás.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/icon_13-plus.png" style={{width: "100px"}} alt="" title="" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Pre koho je tento seminár určený?
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Podujatie je vhodné pre každého od 13 rokov. Prosíme deti mladšie ako 18 rokov aby prišli v sprievode dospelej osoby.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/icon_all-levels.png" style={{width: "100px"}} alt="" title="" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Čo ak nie som veľmi fyzicky zdatný?
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Žiaden problém. Aj keď je tento seminár zábavný a poučný, nebude namáhavý, preto je vhodný pre študentov všetkých vekových kategórií a fyzických schopností.
                                            <br /><br /><br />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/icon_shirt.png" style={{width: "100px"}} alt="" title="" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Čo by som si mal obliecť?
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Pohodlné oblečenie na cvičenie je všetko, čo potrebujete. Odporúčame tričko zakrývajúce ramená a nohavice zakrývajúce kolená.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/icon_grappling.png" style={{width: "100px"}} alt="TURBO CHARGING" title="VOICE CONTROL" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            čo sa naučím?
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Naučíte sa niekoľko jednoduchých, vysoko efektívnych techník z programu Women Empowered. Po skončení seminára pochopíte, prečo sa svet zamiloval do brazílskeho Jiu-Jitsu a prečo je Women Empowered najlepšou cestou pre toľko začiatočníkov po celom svete.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/icon_discount.png" style={{width: "100px"}} alt="" title="" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Čo ak sa mi seminár páči a chcem v ňom pokračovať?
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Pre tých, ktorým sa seminár absolútne páči a chcú sa ďalej vzdelávať, sprístupníme na konci akcie jednorazovú špeciálnu zľavnenú ponuku na naše programy.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="kl-iconbox kl-iconbox--fleft text-left">
                                <div className="kl-iconbox__inner">
                                    <div className="kl-iconbox__icon-wrapper">
                                        <img className="kl-iconbox__icon" src="images/icon_click-here.png" style={{width: "100px"}} alt="" title="" />
                                    </div>

                                    <div className="kl-iconbox__content-wrapper">
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__title-wrapper">
                                            <h3 className="kl-iconbox__title">
                                            Ako si rezervujem miesto?
                                            </h3>
                                        </div>
                                        <div className="kl-iconbox__el-wrapper kl-iconbox__desc-wrapper">
                                            <p className="kl-iconbox__desc">
                                            Ak sa chcete zaregistrovať na bezplatný seminár, prosím vyplňte formulár a rezervujte si  miesto ešte dnes!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </div>


                    </div>


                </div>
            </section>




        </div>
    )
}
export default WESeminarComponent