import React from "react"
import { slide as Menu } from 'react-burger-menu'
import { NavLink } from "react-router-dom"
import { useTranslation } from 'react-i18next'

const MainMenu = () => {
  const {t} = useTranslation('translation')

    var styles = {
      bmCross: {
        background: '#fff'
      },
      bmMenuWrap: {
        position: 'fixed',
        height: '100%'
      },
      bmMenu: {
        // backgroundColor: 'rgba(0, 0, 0, 0.8)',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        padding: '2.5em 1.5em 0',
        fontSize: '1.15em'
      },
      // bmMorphShape: {
      //   fill: '#373a47'
      // },
      bmItemList: {
        color: '#000',
        padding: '0.8em'
      },
      bmItem: {
        display: 'inline-block'
      },
      bmOverlay: {
        background: 'rgba(0, 0, 0, 0.3)'
      },
    }

    return(  
    <div className="site-header-main-center d-flex justify-content-center align-items-center">
    <div className="main-menu-wrapper">


        {/* 
        HAMBURGER MENU
        */}
        <div id="zn-res-menuwrapper">
          <NavLink to="#" className="zn-res-trigger"></NavLink>
        </div> 
        

        <Menu right styles={styles} className="">
            <ul className="">
              <li className="menu-item"><NavLink exact activeClassName="active" to={t('url.gracie-combatives')}>Gracie Combatives<sup>®</sup></NavLink></li>
              <li className="menu-item"><NavLink exact activeClassName="active" to={t('url.gracie-bullyproof')}>Gracie Bullyproof<sup>®</sup></NavLink></li>
              <li className="menu-item"><NavLink exact activeClassName="active" to={t('url.gracie-master-cycle')}>Master Cycle<sup>®</sup></NavLink></li>
              <li className="menu-item"><NavLink exact activeClassName="active" to={t('url.gracie-women-empowered')}>Women Empowered<sup>®</sup></NavLink></li>
              <li className="menu-item"><NavLink exact activeClassName="active" to={t('url.private-class')}>{t('page.private-class')}</NavLink></li>
              {/* <li className="menu-item"><NavLink exact activeClassName="active" to="/101seminar">101 Seminár sebaobrany pre ženy</NavLink></li> */}
              
              <li className="menu-item"><NavLink exact activeClassName="active pt-20" to="/vase-2-percenta-pomozu">Vaše 2% pomôžu</NavLink></li>
              <li className="menu-item"><NavLink exact activeClassName="active" to={t('url.faq')}>Top 10 {t('page.question')}</NavLink></li>
              <li className="menu-item"><NavLink exact activeClassName="active" to="/harmonogram">Harmonogram</NavLink></li>
              
              <li className="menu-item"><NavLink exact activeClassName="active bt-20" to="/kontakt">{t('page.contact')}</NavLink></li>


              {/* <li className="menu-item"><NavLink exact activeClassName="active" to="/eshop">Eshop</NavLink></li> */}
            </ul>
        </Menu>


        <div id="main-menu" className="main-nav zn_mega_wrapper">
  
        
        <ul className="main-menu zn_mega_menu">
        <li className="menu-item-mega-parent1">                        
            <NavLink exact activeClassName="active" to="" className="zn_mega_title">{t('page.programs')}</NavLink>
            <ul className="sub-menu clearfix">
              <li><NavLink to={t('url.gracie-combatives')}>Gracie Combatives<sup>®</sup> <br /><strong>{t('page.beginers')}</strong></NavLink></li>
              <li><NavLink to={t('url.gracie-bullyproof')}>Gracie Bullyproof<sup>®</sup> <br /><strong>{t('page.kids')}</strong></NavLink></li>
              <li><NavLink to={t('url.gracie-master-cycle')}>Master Cycle<sup>®</sup> <br /><strong>{t('page.advanced')}</strong></NavLink></li>
              <li><NavLink to={t('url.gracie-women-empowered')}>Women Empowered<sup>®</sup> <br /><strong>{t('page.womenempowered')}</strong></NavLink></li>
              <li><NavLink to={t('url.private-class')}><strong>{t('page.private-class')}</strong></NavLink></li>
            </ul>
          </li>

          {/* <li className="menu-item-mega-parent"><NavLink exact activeClassName="active" to="/101seminar">101 Seminár sebaobrany pre ženy</NavLink></li> */}
          <li className="menu-item-mega-parent"><NavLink exact activeClassName="active" to={t('url.faq')}>Top 10 {t('page.question')}</NavLink></li>
          <li className="menu-item-mega-parent"><NavLink exact activeClassName="active" to="/harmonogram">Harmonogram</NavLink></li>
          <li className="menu-item-mega-parent"><NavLink exact activeClassName="active" to="/kontakt">{t('page.contact')}</NavLink></li>
          <li className="menu-item-mega-parent"><NavLink exact activeClassName="active" to="/vase-2-percenta-pomozu">Vaše 2% pomôžu</NavLink></li>
          {/* <li className="menu-item-mega-parent"><NavLink exact activeClassName="active" to="/eshop">eshop</NavLink></li> */}
        </ul>

      </div>

</div>
</div>

    )


}

export default MainMenu








// <div className="app-menu navbar-menu">
// <div className="navbar-brand-box">
//     <a href="index.html" className="logo logo-dark">
//     <span className="logo-sm">
//         <img src="assets/images/logo-sm.png"  height={22} />
//     </span>
//     <span className="logo-lg">
//         <img src="assets/images/logo-dark.png"  height={17} />
//     </span>
//     </a>
//     <a href="index.html" className="logo logo-light">
//     <span className="logo-sm">
//         <img src="assets/images/logo-sm.png"  height={22} />
//     </span>
//     <span className="logo-lg">
//         <img src="assets/images/logo-light.png"  height={17} />
//     </span>
//     </a>
//     <button type="button" className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover" id="vertical-hover">
//     <i className="ri-record-circle-line" />
//     </button>
// </div>

// <div id="scrollbar">
//     <div className="container-fluid">
//     <div id="two-column-menu">
//     </div>
//     <ul className="navbar-nav" id="navbar-nav">
//         <li className="menu-title"><span data-key="t-menu">Menu</span></li>

//         <li className="nav-item">
//         <a href="#" className="zn_mega_title" className="nav-NavLink" to="/">
//             <i className="ri-dashboard-2-line" /> <span data-key="t-dashboards">Training session</span>
//         </a>
//         </li> 
//         <li className="nav-item">
//         <a href="#" className="zn_mega_title" className="nav-NavLink" to="/user-profile">
//             <i className="ri-apps-2-line" /> User profile
//         </a>
//         </li>

//         <li className="nav-item">
//         <a className="nav-NavLink menu-NavLink" href="/#" onClick={() => UserService.doLogout()} role="button" aria-expanded="false" aria-controls="sidebarLayouts">
//             <i className="ri-layout-3-line" /> <span data-key="t-layouts">Logout</span> 
//         </a>
//         </li>


//     </ul>
//     </div>
// </div>
// <div className="sidebar-background" />
// </div>
