import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
// import { useTranslation } from 'react-i18next'
import Accordion from 'react-bootstrap/Accordion';

import "../PravidlaPovinnosti.css"
// import CombativesSeminarComponent from "../components/CombativesSeminarComponent"
// import PrihlaskaSeminar from "../components/PrihlaskaSeminar"
// import WESeminarComponent from "../components/WESeminarComponent"

const DvePercenta = () => {
    // const {t} = useTranslation('translation')

    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    
    // useEffect(() => {
    //     if (userProfile.roles) { setHasRoles(true) }
    //     if (userProfile.awards) { setHasAwards(true) }
    //     if (userProfile.adultRanks) { setHasAdultRanks(true) }
    // }, [userProfile])
    

    return (
        <div>
            <PageHeader />



            <div id="page_header" className="page-subheader site-subheader-cst ">
                <div className="bgback" />
                <div className="th-sparkles" />
                <div className="kl-bg-source">
                    <div className="kl-bg-source__bgimage" style={{ backgroundImage: 'url("images/general-bg-kids.jpg")', backgroundRepeat: 'no-repeat', backgroundAttachment: 'scroll', backgroundPosition: '50% 50%', backgroundSize: 'cover' }} />
                    <div className="kl-bg-source__overlay" style={{ backgroundColor: 'rgba(53, 53, 53, 0.1)' }} />
                </div>
                <div className="ph-content-wrap d-flex"><div className="container align-self-center">
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <div className="clearfix" /></div>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <div className="subheader-titles" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: 15 }}>
                                    <h2 className="subheader-maintitle">2% z daní</h2>
                                    <h4 className="subheader-subtitle">Pár minút pre Vás, obrovská pomoc pre nás</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="hg_section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-12">
                            <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-titl">
                                <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">Aj vaše 2% pomôžu</h3>
                                <p>Aj vy môžete tento rok darovať 2% z dane nášmu občianskemu združeniu</p>
                                <p>Preto vás aj tento rok z celého srdca prosíme o dôveru, že vaše 2% z daní použijeme tak zmysluplne a zodpovedne, ako sa len dá.</p>
                                <p>Tu sú presné údaje našej organizácie, ktoré je potrebné uviesť pri vypĺňaní príslušných tlačív:</p>
                                <p>
                                <strong>Obchodné meno:</strong> BJJ Levice<br />
                                <strong>Právna forma:</strong> Občianske združenie<br />
                                <strong>IČO:</strong> 55 261 221<br />
                                <strong>Sídlo:</strong> Holubyho 5, 934 01 Levice 
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id="services" className="hg_section" style={{backgroundColor: "#f0f0f0"}}> 
                <div className="container">

                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="th-spacer clearfix" style={{ height: 25 }}>
                            </div>
                            <div className="kl-title-block clearfix  tbk-symbol--icon tbk--colored tbk-icon-pos--after-titl">
                                <h3 className="tbk__title kl-font-alt fs-larger tcolor fw-bold">Návody ako prispieť 2%</h3>
                            </div>


                            <div className="accordionContent">
                            <Accordion defaultActiveKey="1">
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Návod pre zamestancov</Accordion.Header>
                                <Accordion.Body>

                                <p>1. Požiadajte svojho zamestnávateľa, aby vám vystavil tlačivo <strong>Potvrdenie o zaplatení dane</strong>.</p>
                                <p>2. Z tohto potvrdenia potrebujete <strong>dve veci</strong>. <strong>Vypočítate si vaše 2% </strong>, to bude suma, ktorou nám prispievate, musia to však byť minimálne 3,00€ a uvidíte tam aj <strong>dátum zaplatenia dane</strong>.</p>
                                <p>3. Stiahnite si tlačivo <strong>Vyhlásenie</strong>, nájdete nižšie a riadne vyplňte vaše údaje.</p>
                                <p>4. <strong>S oboma tlačivami</strong> (Potvrdením a Vyhlásením) sa vyberte na váš daňový úrad do podateľne a odovzdajte ho.</p>

                                <p>Hotovo – ďakujeme!</p>
                                <hr />
                                <p><strong><span>Dokumenty:</span></strong></p>
                                <a href="/dokumenty/vyhlasenieFO.pdf" download="" className="box-download" title="VYHLÁSENIE o poukázaní podielu zaplatenej dane z príjmov fyzickej osoby 2024">
                                    <div className="box-download__headline">VYHLÁSENIE o poukázaní podielu zaplatenej dane z príjmov fyzickej osoby 2024 </div>                                    
                                    <div className="button plain download">Stiahnuť</div>
                                </a>

                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="0">
                                <Accordion.Header> Návod pre právnické osoby a firmy</Accordion.Header>
                                <Accordion.Body>

                                <p><strong>Postup je jednoduchší, ako by sa zdalo.</strong></p>

                                <p>Tento krátky návod je určený pre toho, kto Vašej spoločnosti.</p>

                                <p><strong>1. Vypočítajte sumu príspevku</strong> – teda Vaše 2%* z dane z príjmov právnickej osoby. Musí to však byť minimálne 8,00€.</p>
                                <p><strong>2. V časti VI. (časť 6.) daňového priznania za kalendárny rok (DPPO) uveďte, prosím, našu organizáciu ako príjemcu a sumu, ktorú poukazujete.</strong>   </p>
                                <p><strong>3. Toto daňové priznanie odošlite</strong></p>
                                <p>Hotovo. Ďakujeme!</p>
                                </Accordion.Body>
                            </Accordion.Item>



                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Návod pre fyzické osoby</Accordion.Header>
                                <Accordion.Body>
                                <p>Aj fyzické osoby, ktoré si podávajú daňové priznanie (DPFO), môžu veľmi jednoducho poukázať našej organizácii svoje 2%.</p>

                                <p><strong>1. Pri vyplňovaní svojho formulára daňového priznania (DPFO) si vypočítajte vaše 2% z dane. Musí to však byť minimálne 3,00€.</strong></p>
                                <p><strong>2. V časti VIII. (časť 8.) daňového priznania uveďte, prosím, našu organizáciu ako príjemcu a sumu, ktorú poukazujete.</strong></p>
                                <p><strong>3. Toto daňové priznanie odovzdajte</strong></p>
                                <p>Hotovo. Ďakujeme!</p>
                                </Accordion.Body>
                            </Accordion.Item>                            
                            </Accordion>



                         
                            </div>

                        </div>


                    </div>


                </div>
            </section>





            
            <PageFooter />
        </div>
    )
}

export default DvePercenta

