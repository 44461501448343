import React from "react"
import PageHeader from "../components/Header"
// import UserDetail from "../components/UserDetail"
// import PageSlider from "../components/Slider"
// import WelcomePage from "../components/WelcomePage"
// import Selector from "../components/Selector"
// import FreeTrial from "../components/FreeTrial"
import PageFooter from "../components/Footer"
// import { useTranslation } from 'react-i18next'

import "../PravidlaPovinnosti.css"
// import CombativesSeminarComponent from "../components/CombativesSeminarComponent"
import PrihlaskaSeminar from "../components/PrihlaskaSeminar"
import WESeminarComponent from "../components/WESeminarComponent"

const OneOOneSeminar = () => {
    // const {t} = useTranslation('translation')

    // const dispatch = useDispatch()
    // const { userProfile } = useSelector((state) => state)
    // const debug = JSON.stringify(userProfile, null, 2)

    // const [hasRoles, setHasRoles] = useState(false)
    // const [hasAwards, setHasAwards] = useState(false)
    // const [hasAdultRanks, setHasAdultRanks] = useState(false)
    
    // useEffect(() => {
    //     if (userProfile.roles) { setHasRoles(true) }
    //     if (userProfile.awards) { setHasAwards(true) }
    //     if (userProfile.adultRanks) { setHasAdultRanks(true) }
    // }, [userProfile])
    

    return (
        <div>
            <PageHeader />

            {/* <CombativesSeminarComponent /> */}
            <WESeminarComponent />
            <PrihlaskaSeminar />




            
            <PageFooter />
        </div>
    )
}

export default OneOOneSeminar

